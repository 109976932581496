import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const Section = styled.div`
  position: relative;
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    position: relative;
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 13px;
  }

  & .form-duuf-textarea {
    min-height: 245px;
    border-radius: 24px !important;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding-bottom: 0.6rem;
    color: ${({ theme }) => theme.color.light} !important;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-error {
    position: absolute;
    top: 0;
    right: 0;
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledBlockPrimary = styled(BlockPrimary)`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 50px !important;
`

const Content = styled(ParseContent)`
  font-size: ${({ theme }) => theme.font.size.small} !important;
`

const FormSupport: React.FC<FormProps> = ({ fields }) => (
  <Section className="mb-5 pb-5">
    <div className="container">
      <StyledFormDuuf id={2} generate={false}>
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <StyledBlockPrimary>
              <div className="px-sm-4">
                <div className="d-flex flex-column flex-sm-row">
                  <ParseContent
                    content={fields.description}
                    className="mb-sm-5"
                  />
                  <Content
                    content={fields.descriptionhighlighted}
                    className="ms-sm-4 mt-3 mb-2 mb-sm-0"
                  />
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup hideIds={[4]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[4]} />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <FormDuufSubmit />
              </div>
            </StyledBlockPrimary>
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </Section>
)

export default FormSupport
