import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Images
import BackgroundCircle from 'img/backgroundcircle.inline.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const Section = styled.div<{ paddingTop: boolean }>`
  position: relative;
  font-family: ${({ theme }) => theme.font.family.primary};

  @media (max-width: 1439px) {
    ${(props) =>
      !props.paddingTop &&
      css`
        padding-top: 100px;

        @media (max-width: 991px) {
          padding-top: 300px;
        }
      `}
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-right: 13px;
    padding-left: 13px;
  }

  & .form-duuf-textarea {
    min-height: 245px;
    border-radius: 24px !important;
    padding-top: 5px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding-bottom: 0.6rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  .form-duuf-error-consent {
    position: absolute;

    @media (min-width: 576px) {
      top: 53px !important;
    }

    @media (max-width: 575.98px) {
      top: 22px !important;
      margin-top: 50px;
    }
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    right: 10px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    text-align: center;
    font-size: 20px;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-duuf-group-consent {
    & > div {
      align-items: flex-start !important;
    }

    & input {
      width: 20px;
      height: 16px;
      margin-top: 0.17rem !important;
      margin-right: 0.75rem !important;
      appearance: none;
      border: 1px solid #c4c4c4 !important;

      &:checked {
        background-color: ${({ theme }) => theme.color.primary};
      }

      &:hover {
        cursor: pointer;
      }
    }

    & a {
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: -600px;
  left: 0;
  z-index: -1;
  transform: rotate(180deg);
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location: any
}

const FormContact: React.FC<FormContactProps> = ({ fields, location }) => (
  <Section
    className="mb-5 pb-5"
    id="contact"
    paddingTop={location.pathname.includes('/en')}
  >
    <BackgroundWrapper>
      <BackgroundCircle />
    </BackgroundWrapper>
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
      <StyledFormDuuf
        id={location.pathname.includes('/en') ? 4 : 1}
        className="mt-5"
        generate={false}
        privacyUrl="/privacyverklaring"
        privacyUrlPlacement="privacy statement"
      >
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <BlockPrimary>
              <div className="px-lg-4">
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup hideIds={[2, 3]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[2]} hideIds={[3]} />
                  </div>
                </div>
                <div className="row mb-4">
                  <FormDuufGroup showIds={[3]} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <FormDuufSubmit />
              </div>
            </BlockPrimary>
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </Section>
)

export default FormContact
